<template>
  <div class="container">
    <section class="orders-header">
      <h1 class="titlePage titlePage_textLeft">
        {{ getUserName }}
      </h1>

      <breadcrumbs v-if="!isEdit"/>

      <h2 class="titlePage_subtitle titlePage">
        SELECT GRADE
        <label
          v-if="checkBox"
          class="form__label"
        >
          <input
            v-model="selectAll"
            type="checkbox"
            hidden="hidden"
            class="form__checkbox"
            @change="selectAllItems"
          >
          <span class="form__checkbox_icon" />
          <span class="form__labelTitle">Select All</span>
        </label>
      </h2>
    </section>
    <section class="item-types">
      <div class="row">
        <div
          v-for="type in grade"
          :key="type.id"
          class="col-md-2 col-sm-4 col-6"
        >
          <label
            v-if="checkBox"
            class="box"
          >
            <input
              v-model="all"
              type="checkbox"
              hidden="hidden"
              class="form__checkbox"
              :value="type.id"
              @change="addToSelected(type)"
            >
            <span class="form__checkbox_icon" />
            <span class="">
              <img
                :src="type.icon"
                :alt="type.name"
                class=""
              >
            </span>
            <span class="text_default">{{ type.name }}</span>
          </label>

          <div
            v-else
            class="box"
            @click="selectGrade(type)"
          >
            <p class="">
              <img
                :src="type.icon"
                :alt="type.name"
                class=""
              >
            </p>
            <p class="text_default">
              {{ type.name }}
            </p>
          </div>
        </div>
      </div>
    </section>

    <button
      v-if="checkBox"
      type="button"
      class="btn btn_transparent"
      :disabled="Object.keys(selected).length < 1"
      @click="selectGrade"
    >
      <svg-icon name="solid/step-forward"/> Next
    </button>
  </div>
</template>

<script>
import router from '../../router'
import breadcrumbs from './../parts/breadcrumbs'
import Vue from 'vue'

export default {
    name: 'SelectGrade',
    components: {breadcrumbs},
    props: {
        checkBox: Boolean
    },
    data() {
        return {
            all: [],
            selected: {},
            selectAll: false,
        }
    },

    computed: {
        getUserName() {
            return localStorage.getItem('nameUser')
        },
        user() {
            return this.$store.getters['userList/user']
        },
        grade() {
            return this.$store.getters['grade/grade']
        },
        isEdit() {
            return this.$store.getters['orderPosition/isCompleted']
        },
    },

    watch: {
        'all'() {
            if (this.all.length === this.grade.length) {
                this.selectAll = true
            }
            else {
                this.selectAll = false
            }
        }
    },

    created() {
        this.showPrew()
        if (this.checkBox) {
            this.$store.dispatch('grade/getGradeToPackage')
        }
        else this.$store.dispatch('grade/getGrade')
    },

    methods: {
        selectGrade(target) {
            if (this.checkBox) {
                this.$store.commit('orderPosition/setGrade', target)

                let path = this.$store.getters['app/orderBuilderPath']('grade_id>' + this.selected[Object.keys(this.selected)[0]].id)

                if (Object.keys(this.selected).length > 0) {
                    this.$store.dispatch(
                        'app/addBreadcrumb',
                        {
                            route: {
                                path: path
                            },
                            name: 'Grade',
                            selected: this.selected,
                        }
                    )
                    //            router.push({path: path});
                }
            }
            else {
                if(this.isEdit) {
                    this.$store.dispatch('orderPosition/updateGrade', target)
                }else {
                    this.$store.commit('orderPosition/setGrade', target)
                    this.$store.commit('orderPosition/nextStep', 'Frame Colour')
                }
            }
        },

        addToSelected(el) {
            if (this.selected['k' + el.id]) {
                Vue.delete(this.selected, 'k' + el.id)
            } else {
                Vue.set(this.selected, 'k' + el.id, {id: el.id, name: el.name})
            }
        },

        showPrew() {
            let d = this.$store.getters['createdOrder/createdOrder'].items.grade_id
            if (Object.keys(d).length > 0) {
                if (_.isUndefined(d[Object.keys(d)[0]].icon)) {
                    Vue.set(this, 'selected', Object.assign({}, d))
                }
                else {
                    Vue.set(this, 'selected',
                        _.reduce(d, (memo, item) => {
                            memo['k' + item.id] = item
                            return memo
                        }, {}
                        ))
                }

                if (Object.keys(this.selected).length === 0) {
                    this.selected = []
                }
                else {
                    this.all = this.$store.getters['createdOrder/filterGrade']
                }
            }
        },

        selectAllItems() {
            let d = this.grade
            if (this.selectAll) {
                Vue.set(this, 'selected', _.reduce(d, (memo, item) => {
                    memo['k' + item.id] = item
                    return memo
                }, {}
                ))
                this.all = []
                _.forEach(d, (el) => {
                    this.all.push(el.id)
                })
            }
            else{
                this.all = []
                this.selected = {}
            }
        },
    },
}
</script>

<style scoped lang="scss">
  @import "../../assets/scss/utils/vars";

  .box {
    margin-bottom: 1rem;
    display: block;
    cursor: pointer;

    img {
      display: block;
      margin: 0 auto;
      margin-bottom: 20px;
      width: 100%;
      max-width: 160px;
      max-height: 160px;
    }
    p, span {
      display: block;
      text-align: center;
    }
  }

  .form {
    &__labelTitle {
      color: $navy;
    }
    &__checkbox_icon {
      margin: 10px 10px 5px 0;
    }
  }

  .titlePage_subtitle {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    white-space: nowrap;
    .form {
      &__label {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 0 10px;
      }

      &__checkbox_icon {
        margin: 5px;
      }

      &__labelTitle {
        font-size: $defSize;
      }
    }
  }

  .btn_transparent {
    display: block;
    margin: 0;
    position: absolute;
    bottom: 3rem;
    left: 150px;
    &:disabled {
      opacity: .6;
    }
    svg {
      margin: 0;
    }
  }
</style>
